<template>
    <div v-if="data.cards.length" :id="data.id" class="relative">
        <div class="flex flex-row">
            <h2 class="mb-8 w-full">
                {{ relatedFaqs ? 'Related FAQs' : data.heading }}
            </h2>
        </div>
        <div
            :class="[
                relatedFaqs || twoColumns
                    ? 'flex flex-col gap-4 md:flex-row'
                    : '',
            ]"
        >
            <div
                class="flex flex-col gap-4"
                :class="[
                    relatedFaqs || twoColumns ? 'w-full md:w-1/2' : 'w-full',
                ]"
            >
                <Card
                    v-for="(faq, index) in relatedFaqs || twoColumns
                        ? cardsFirstColumn
                        : data.cards"
                    :id="faq.id"
                    :key="'faq-card-' + index"
                    class="w-full self-start px-4 py-2"
                    :v-editable="faq.vEditable"
                >
                    <Collapse
                        icon="plusminus"
                        :is-expanded="'#' + faq.id === selectedFaq"
                        :title-is-clickable="true"
                        itemscope
                        itemprop="mainEntity"
                        itemtype="https://schema.org/Question"
                    >
                        <template #title>
                            <h3 itemprop="name" class="group-hover:text-brew">
                                {{ faq.title }}
                            </h3>
                        </template>

                        <div
                            class="border-t border-grind py-6"
                            itemscope
                            itemprop="acceptedAnswer"
                            itemtype="https://schema.org/Answer"
                        >
                            <div itemprop="text">
                                <WysiwygContent :content="faq.content" />
                            </div>
                        </div>
                    </Collapse>
                </Card>
            </div>
            <div
                v-if="relatedFaqs || twoColumns"
                class="flex flex-col gap-4"
                :class="[
                    relatedFaqs || twoColumns ? 'w-full md:w-1/2' : 'w-full',
                ]"
            >
                <Card
                    v-for="(faq, index) in cardsSecondColumn"
                    :id="faq.id"
                    :key="'faq-card-' + index"
                    class="w-full self-start px-4 py-2"
                    :v-editable="faq.vEditable"
                >
                    <Collapse
                        icon="plusminus"
                        :is-expanded="'#' + faq.id === selectedFaq"
                        :title-is-clickable="true"
                        itemscope
                        itemprop="mainEntity"
                        itemtype="https://schema.org/Question"
                    >
                        <template #title>
                            <h3 itemprop="name" class="group-hover:text-brew">
                                {{ faq.title }}
                            </h3>
                        </template>

                        <div
                            class="border-t border-grind py-6"
                            itemscope
                            itemprop="acceptedAnswer"
                            itemtype="https://schema.org/Answer"
                        >
                            <div itemprop="text">
                                <WysiwygContent :content="faq.content" />
                            </div>
                        </div>
                    </Collapse>
                </Card>
            </div>
        </div>
        <div class="mt-8 md:flex md:justify-center">
            <Button
                v-if="actionButton"
                :button="actionButton"
                class="md:px-20"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
import Card from '~/components/page-building/components/Card.vue';
import Button from '~/components/page-building/components/Button.vue';
import FaqCardGroupType from '~/types/FaqCardGroupType';
import ButtonType from '~/types/ButtonType';

const props = withDefaults(
    defineProps<{
        data: FaqCardGroupType;
        relatedFaqs?: boolean;
        actionButton?: ButtonType | null;
        selectedFaq?: string;
        twoColumns?: boolean;
    }>(),
    {
        relatedFaqs: false,
        actionButton: null,
        selectedFaq: '',
        twoColumns: false,
    },
);

const middle = ref(Math.ceil(props.data.cards.length / 2));

const cardsFirstColumn = computed(() => {
    return props.data.cards.slice(0, middle.value);
});

const cardsSecondColumn = computed(() => {
    return props.data.cards.slice(middle.value);
});
</script>
